
import { reactive, onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { Actions } from "@/store/enums/ConsumerOrderEnums.ts";
import { Actions as OrderStatusActions } from '@/store/enums/OrderStatusEnums.ts';
import Table from '@/components/custom/Table.vue';
import moment from 'moment'
import numeral from 'numeral'

export default {
  name : 'ConsumerOrdersList',
  components : {
    Table
  },
  setup(props,{slots}){

    const store = useStore();

    store.dispatch(OrderStatusActions.GET_ORDER_STATUSES);
    onMounted(() => {
      setCurrentPageBreadcrumbs("Consumer Orders",[
        {title : "Consumer Orders List",to : "/orders/consumer/list"}
      ]);
    });

    const actions = computed(() => {
      return Actions;
    })

    const additionalFilters = reactive({
      startDate : moment(new Date('2021-01-01')).format('YYYY-MM-DD'),
      endDate : moment(new Date()).format('YYYY-MM-DD'),
      order_status_id : 2
    })

    const columns = [
      {name : 'uoid', title : 'Order ID', sortable : true, sort : null, searchable : false},
      {name : 'reference', title : 'Ref', sortable : true, sort : null, searchable : true},
      {name : 'status', title : 'Order Status', sortable : true, sort : null, searchable : false},
      {name : 'orderDate', title : 'Order Date', sortable : true, sort : 'desc', searchable : true},
      {name : 'consumer', title : 'Consumer',sortable : true, sort : null, searchable : true},
      {name : 'products', title : 'Products',sortable : false, sort : null, searchable : true},
      {name : 'num_of_products', title : 'Num. of Products',sortable : false, sort : null, searchable : false},
      {name : 'num_of_items', title : 'Num. of Items',sortable : false, sort : null, searchable : false},
      {name : 'cost', title : 'Cost',sortable : false, sort : null, searchable : false},
      {name : 'actions', title : 'View Order',sortable : false, sort : null, searchable : false}
    ]

    return {
      columns,
      actions,
      additionalFilters,
      store,
      moment,
      numeral
    }

  }
}
